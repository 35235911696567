export const VERSION = "Version 1.16.5";
export const LANGUAGE = "en";
export const STORE_ID = 65;
export const BRAND_ID = 3;
export const DELIVERY_TRESHOLD = 2;
export const API_VERSION = "v1";
export const BASE_URL = "https://admin.ourismarket.com";
export const UPS_DEVELOPMENT_BASE_URL = "https://wwwcie.ups.com";
export const UPS_PRODUCTION_BASE_URL = "https://onlinetools.ups.com";
export const DEVICE_DATA = "device_id=1111&device_token=111&platform=web";
export const DEFAULT_PRODUCT_IMAGE = "/images/default-image.jpg";
export const DEFAULT_SMALL_PRODUCT_IMAGE = "/images/default-image-small.jpg";
export const CURRENCY = "$";
export const BANQUEST_SOURCE_KEY = "pk_aLlK9MUSXWz3n7LBB9G4KJEv4rzKs";
export const NATIONWIDE_COLLECTIONS = [
  "29839",
  "29842",
  "29846",
  "30000",
  "29847",
  "29849",
  "29852",
  "29851",
  "29857",
  "29922",
  "29843",
  "29844",
  "29845",
  "30002",
  "30003",
  "30006",
];
export const DRY_ICE_BASE_COST = 14.95;
export const DRY_ICE_BASE_COST_INCREMENT = 300;
export const FETCH_DATA = async (
  url,
  keys,
  method = "GET",
  bodyData = null,
  contentType = "application/json"
) => {
  let result = {
    loaded: false,
    success: false,
    error: false,
    message: "",
    data: null,
  };

  try {
    const options = {
      method,
      headers: {
        "Content-Type": contentType,
      },
    };

    // Handle POST body depending on content type
    if (method === "POST" && bodyData) {
      if (contentType === "application/x-www-form-urlencoded") {
        // Encode the body as URLSearchParams for x-www-form-urlencoded
        options.body = new URLSearchParams(bodyData).toString();
      } else if (contentType === "application/json") {
        // Stringify the body for JSON content type
        options.body = JSON.stringify(bodyData);
      }
    }

    const res = await fetch(url, options);

    // if (url.includes("updateCheckoutObject")) {
    //   console.log("FETCH_DATA IS OK before", res);
    //   const r = await res.json();
    //   console.log("FETCH_DATA IS OK", r);
    // }

    // if (url.includes("tax_calculation")) {
    //   const r = await res.json();
    //   console.log("RESPONSE", r);
    // }
    if (res.ok) {
      const data = await res.json();

      if (data.success) {
        if (Array.isArray(keys)) {
          // Create an object with all the requested keys
          result.data = keys.reduce((acc, key) => {
            acc[key] = data[key];
            return acc;
          }, {});
        } else {
          // If it's a single key, return the value of that key
          result.data = data[keys];
          // if (url.includes("authentication")) {
          //   console.log("Result.data", result.data);
          // }
        }
        result.loaded = true;
        result.success = true;
        result.error = false;
      } else {
        result.loaded = true;
        result.success = false;
        result.error = true;
        result.message = data.message || "Data fetch unsuccessful";
      }
    } else {
      result.loaded = true;
      result.success = false;
      result.error = true;
      result.message = `Failed to fetch: ${res.statusText}`;
    }
  } catch (error) {
    result.loaded = true;
    result.error = true;
    result.success = false;
    result.message = `Error: ${error.message}`;
  }

  return result;
};

export const VALIDATE_PHONE_NUMBER = (phoneNumber) => {
  const phoneRegex = /^[0-9]{10}$/; // Validates US 10-digit phone numbers (adjust regex for other countries)
  return phoneRegex.test(phoneNumber);
};

export const VALIDATE_ZIPCODE = (zipcode) => {
  const zipCodePattern = /^\d{5}$/; // Regex for 5-digit zip code
  return zipCodePattern.test(zipcode);
};

// Format phone number as (XXX) XXX-XXXX
export const FORMAT_PHONE_NUMBER = (value) => {
  if (!value) return value;

  // Remove all non-digit characters from the input
  const phoneNumber = value.replace(/[^\d]/g, "");

  const phoneNumberLength = phoneNumber.length;

  // Format phone number based on length
  if (phoneNumberLength < 4) {
    return phoneNumber;
  } else if (phoneNumberLength < 7) {
    return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
  } else {
    return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(
      3,
      6
    )}-${phoneNumber.slice(6, 10)}`;
  }
};
